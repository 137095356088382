// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "jquery"
import "bootstrap"

import "trix"
import "@rails/actiontext"

import "chartkick/chart.js"

import "select2"

import "moment"
import "tempusdominus-bootstrap-4"

document.addEventListener('turbolinks:load', () => {

  // for auto close message notification
  $('.alert').first().hide().slideDown(0).delay(10000).slideUp(0, function () {
   $(this).remove();
  });

  // for enable tooltips everywhere
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  });

  // for cards carousel
  $('#recipeCarousel').carousel({
    interval: 10000
  });
  $('.carousel .carousel-item').each(function(){
    var minPerSlide = 3;
    var next = $(this).next();
    if (!next.length) {
      next = $(this).siblings(':first');
    }
    next.children(':first-child').clone().appendTo($(this));

    for (var i=0;i<minPerSlide;i++) {
      next=next.next();
      if (!next.length) {
        next = $(this).siblings(':first');
      }

      next.children(':first-child').clone().appendTo($(this));
    }
  });

  // for select2
  $('#select2-multiple').select2({
    tags: true
  });

  // for tempusdominus-bootstrap-4
  $('#datetimepicker').datetimepicker({
    format: 'YYYY-MM-DD',
    autoHide: true
  });

  $('#datetimepicker2').datetimepicker({
    format: 'YYYY-MM-DD',
    autoHide: true
  });

});

Rails.start()
Turbolinks.start()
ActiveStorage.start()
